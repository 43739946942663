<template>
  <div>
    <div class="installation-container-info">
      <h2>Contactos</h2>
      <div class="installation-block-info row">
        <div>
          <p class="sub-title">Morada</p>
          <p>
            {{ info.address }}
          </p>
          <p>
            {{ info.municipality }}
          </p>
          <p>
            {{ info.postalCode }}
          </p>
        </div>
        <div v-if="info.contacts.length > 0">
          <p class="sub-title">Contactos</p>
          <p v-for="(contact, index) in info.contacts" :key="index">
            {{ contact }}
          </p>
        </div>
        <div v-if="info.services.length > 0">
          <p class="sub-title">Os Nossos Serviços</p>
          <div
            class="services-list"
            v-for="(service, index) in info.services"
            :key="index"
          >
            <img :src="getServiceIcon(service.serviceID).icon" />
            <p>
              {{ getServiceIcon(service.serviceID).name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="installation-container-info">
      <h2>Horários</h2>
      <div class="installation-block-info row">
        <div v-for="(service, index) in info.services" :key="index">
          <p class="sub-title">
            {{
              getServiceIcon(service.serviceID).name
                ? getServiceIcon(service.serviceID).name
                : service.serviceName
            }}
          </p>
          <div v-for="(schedule, index) in service.schedules" :key="index">
            <p class="weekdays">{{ schedule.weekdays }}</p>
            <p v-if="schedule.hourDay">{{ schedule.hourDay }}</p>
            <p v-if="schedule.morningHour">{{ schedule.morningHour }}</p>
            <p v-if="schedule.afternoonHour">{{ schedule.afternoonHour }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstallationsInfo",
  props: {
    info: { type: Object },
  },
  data() {
    return {
      services: this.$store.state.instalacoesServicos,
    };
  },
  methods: {
    getServiceIcon(service) {
      const fullService = this.services.find((element) => {
        return element.serviceId == service;
      });

      return fullService
        ? {
            icon: fullService.serviceIcon,
            name: fullService.serviceDesignation,
          }
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Installations";
</style>
