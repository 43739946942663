<template>
  <div class="installation-header">
    <div class="image-container red-left">
      <img
        v-if="image"
        class="img-cover"
        :src="image"
        :alt="name"
        :title="name"
      />
    </div>
    <div class="installation-container">
      <div>
        <h1>{{ name }}</h1>
        <p class="sub-title">{{ address }}</p>
        <p v-html="mainText"></p>
      </div>
      <ButtonsWrapper
        :data="installationCta"
        wrapperClass="primary-secondary row "
      />
    </div>
  </div>
</template>

<script>
import ButtonsWrapper from "@/components/Buttons/ButtonsWrapper.vue";

export default {
  name: "InstallationsHeader",
  components: {
    ButtonsWrapper,
  },
  props: {
    name: { type: String, default: "" },
    address: { type: String, default: "" },
    id: { type: String, default: "" },
    image: { type: String, default: "" },
    lat: { type: String, default: "" },
    lng: { type: String, default: "" },
  },
  data() {
    return {
      installationCta: [
        {
          buttonLink: "",
          buttonLabel: "Obter direções",
          externalLink: true,
        },
        {
          buttonName: "Testdrive",
          buttonLabel: "Agendar Test-Drive",
          buttonParams: {
            dealerSelected: this.id,
          },
        },
        {
          buttonName: "Usados",
          buttonLabel: "Consultar stock de usados",
          buttonParams: {
            dealer: this.id,
          },
        },
      ],
      dealerName: this.$store.state.dealerInfo.dealerName,
      preMainText: this.$store.state.instalacoesContentSingle,
      mainText: "",
    };
  },
  created() {
    this.installationCta[0].buttonLink =
      "http://www.google.com/maps/place/" + this.lat + "," + this.lng;
    this.replaceBOVariable("{#dealer}", this.dealerName);
    this.replaceBOVariable("{#installation}", this.name);
  },
  methods: {
    replaceBOVariable(variable, text) {
      this.mainText = this.preMainText.replaceAll(variable, text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Installations";
</style>
