<template>
  <div>
    <Section>
      <InstallationHeader
        :name="data.fullName"
        :address="data.address"
        :id="data.id"
        :image="data.image"
        :lat="data.lat"
        :lng="data.lng"
      />
    </Section>
    <Section>
      <InstallationInfo :info="data" />
    </Section>
  </div>
</template>

<script>
import InstallationHeader from "@/components/Installations/InstallationHeader";
import InstallationInfo from "@/components/Installations/InstallationInfo";
import Section from "@/components/Section/Section";

export default {
  name: "InstallationsDetail",
  components: {
    InstallationHeader,
    InstallationInfo,
    Section,
  },
  props: {
    data: { type: Object },
  },
};
</script>

<style lang="scss" scoped>
@import "/Installations";
</style>
