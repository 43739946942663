<template>
  <div class="wrapper">
    <BackLink :data="backlink" />
    <InstallationsDetail :data="installationDetail" />
    <Section>
      <SEO
        :seo="seo.single"
        :banner="seo.single.seoBannerImage"
        :link="seo.single.seoBannerLink"
        :installation="installationDetail.fullName"
      />
    </Section>
    <CTA />
  </div>
</template>

<script>
import BackLink from "@/components/Index/BackLink";
import CTA from "@/components/CTA/CTA";
import InstallationsDetail from "@/components/Installations/InstallationsDetail";
import Section from "@/components/Section/Section.vue";
import SEO from "@/components/SEO/SEO.vue";

import { manageSEO } from "@/services/dataManagement.js";

export default {
  name: "InstalacoesSingle",
  metaInfo() {
    return manageSEO(
      this.seo,
      this.seoHomepage,
      true,
      this.dealerName,
      this.installationDetail.fullName
    );
  },
  components: {
    BackLink,
    CTA,
    InstallationsDetail,
    Section,
    SEO,
  },
  data() {
    return {
      dealerName: this.$store.state.dealerInfo.dealerName,
      backlink: {
        routting: "Instalacoes",
        label: "instalações",
      },
      installations: this.$store.state.allInstallations,
    };
  },
  computed: {
    instalacoesContent() {
      return this.$store.state.instalacoesContent;
    },
    seo() {
      return this.$store.state.instalacoesSEO;
    },
    seoHomepage() {
      return this.$store.state.homepage.seo;
    },
    installationDetail() {
      return this.$store.getters.getNewSingleInstallationArray(
        this.$route.params.id
      );
    },
  },
};
</script>
